import * as React from 'react';
import { NextPage } from 'next';
import Link from 'next/link';
import { useSearchParams } from 'next/navigation';
import Layout from '../components/Layout';
import { SignIn } from '../components/SignIn';
import { H1, Small } from '../components/styled/text';
import { GridContainer } from '../components/styled/layout';

import classes from '@/components/pages/login/login.module.scss';

const Login: NextPage = () => {
  const searchParams = useSearchParams();
  const redirectTo = searchParams.get('redirectTo'); //use https://beta.nextjs.org/docs/api-reference/file-conventions/page#searchparams-optional when in app

  return (
    <Layout title="Iniciar sesión | Anna&Co" isCommerceOn={false}>
      <GridContainer>
        <H1>Iniciar sesión</H1>
        <div className={classes.signupIntro}>
          <Small>
            Si todavía no tienes cuenta, puedes{' '}
            <Link className={classes.linkInline} href="/signup" passHref>
              crear una ahora
            </Link>{' '}
          </Small>
        </div>
        <div className={classes.formContainer}>
          <SignIn redirect={redirectTo ?? '/account'} />
        </div>
        <div className={classes.forgotPassword}>
          ¿Has olvidado tu contraseña?
          <Link
            className={classes.askForNewLink}
            href="/reset-password"
            passHref
          >
            pide una nueva
          </Link>
        </div>
      </GridContainer>
    </Layout>
  );
};

export default Login;
